import React from 'react';
import PropTypes from 'prop-types';
import { Card } from '@lxlabs/change-ad-components';
import styled from 'styled-components';
import SideImage from './sideImage';

const Style = styled.div`
  background-color: var(--color-primary10);
`;

const SkinCard = ({ image, backgroundColor }) => (
  <Card
    backgroundColor={backgroundColor}
    padding={{
      top: 'medium', bottom: 'medium', left: 'medium', right: 'medium',
    }}
  >
    <Style>
      {image}
    </Style>
  </Card>
);

SkinCard.propTypes = {
  image: PropTypes.oneOfType([SideImage]).isRequired,
  backgroundColor: PropTypes.string,
};

SkinCard.defaultProps = {
  backgroundColor: 'white',
};

export default SkinCard;
